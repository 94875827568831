import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";
import { Input } from "../../../../../components/ui/_forms/Input";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Enums } from "../../../../../enums";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";
import { StakeholderCard } from "../../../stakeholders/StakeholderCard";
import { ImpactStakeholderListViewModel } from "./ImpactStakeholderListView_model";

export interface ImpactStakeholderListViewProps {
  organisationId: number;
  projectId: number;
  impactGroupId: number;
}

const ImpactStakeholderListView: React.FunctionComponent<ImpactStakeholderListViewProps> = observer(
  ({ organisationId, projectId, impactGroupId }) => {
    const [model, setModel] = useState(
      () => new ImpactStakeholderListViewModel(+organisationId, +projectId, impactGroupId)
    );

    useEffect(() => {
      if (model.projectId !== +projectId || model.organisationId !== organisationId) {
        setModel(new ImpactStakeholderListViewModel(+organisationId, +projectId, impactGroupId));
      }
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
      // eslint-disable-next-line
    }, []);

    const initUrl = `/organisations/${organisationId}/projects/${model.projectId}`;

    if (model.isLoading) {
      return <PositionedSpinner />;
    }
    return (
      <div className={`container-fluid stakeholder-action-list pt-5 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col-12">{I18n.t("phrases.highLevelImpactStakeholderDescription")}</div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="d-flex justify-content-end">
              <ButtonIcon
                symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                iconSize={Enums.UiSizes.SM}
                type={ButtonTypes.OUTLINE_PRIMARY}
                onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
              />
            </div>

            <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                icon={IconSymbols.Search}
                value={model.searchValue}
                onBlur={null}
                className="mt-3"
              />
            </AnimateHeight>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {model.filteredStakeholders?.length > 0 ? (
              model.filteredStakeholders.map(stakeholder => {
                return (
                  <Link
                    key={stakeholder.id}
                    to={`${initUrl}/stakeholders/${stakeholder.id}?${
                      QUERY_STRING_PARAMS.RETURN_URL
                    }=${encodeURIComponent(`${initUrl}/impactgroups/${impactGroupId}`)}`}
                  >
                    <StakeholderCard stakeholder={stakeholder} projectId={model.projectId} />
                  </Link>
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noImpactStakeholders")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export { ImpactStakeholderListView };
