import { PieChart } from "@flightpath/coreui/dist/widgets/pie";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { PieTooltip } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ActionsPerProjectByProgressStatusSidebarModel } from "./ActionsPerProjectByProgressStatusSidebar_model";
import { useFlightPathUser } from "../../../../../../core/auth_insight";

export interface ActionsPerProjectByProgressStatusSidebarProps {
  userId: number;
  progressStatus: number;
}

export const ActionsPerProjectByProgressStatusSidebar: React.FC<ActionsPerProjectByProgressStatusSidebarProps> =
  observer(props => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const navigate = useNavigate();
    const [model, setModel] = useState(
      () =>
        new ActionsPerProjectByProgressStatusSidebarModel(
          authUser,
          organisationId,
          props.userId,
          props.progressStatus,
          navigate
        )
    );

    useEffect(() => {
      setModel(
        new ActionsPerProjectByProgressStatusSidebarModel(
          authUser,
          organisationId,
          props.userId,
          props.progressStatus,
          navigate
        )
      );
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      model.onMount();
    }, [model]);

    if (!model || model.isLoading) return <PositionedSpinner />;

    return (
      <div style={{ width: "100%", height: "70vh" }}>
        <div className="w-100 h-100 d-flex  justify-content-center align-items-center">
          <PieChart
            data={model.drillDownPieData}
            tooltipFn={PieTooltip}
            colors={model.getPieColors()}
            hasLinkLabels={true}
            margin={{
              top: 120,
              right: 100,
              bottom: 100,
              left: 100
            }}
            onClickFn={model.onPieClick}
          />
        </div>
      </div>
    );
  });
