import { AxiosRequestConfig } from "axios";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IFlightPathApiResponse } from "../../../../../../services/api/v2/BaseApiModel";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { CustomColumnFieldTypeEnum } from "../../../enums/AgGridCustomColDefEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { ISdColDef } from "../../columns/baseColumn/BaseColumnTypes";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import { DynamicColumnBuilder } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_builder";
import { DYNAMIC_COLUMN_CONFIG } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_config";
import { PillsColumnBuilder } from "../../columns/commonColumns/PillsColumn/PillsColumn_builder";
import { PILLS_COLUMN_CONFIG, PILLS_FILTER_CONFIG } from "../../columns/commonColumns/PillsColumn/PillsColumn_config";
import { GridModalBuilder } from "../../modals/GridModal_builder";

export abstract class BaseGridColumnBuilder {
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  organisationId: number;
  projectId: number;
  updateField: (
    organisationId: number,
    projectId: number,
    actionId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: any,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<any>>;
  gridModalBuilder: GridModalBuilder;
  canEdit: boolean;

  /**
   *
   */
  constructor(updateField: any, organisationId: number, projectId: number, canEdit: boolean) {
    this.updateField = updateField;
    this.organisationId = organisationId;
    this.canEdit = canEdit;
    this.projectId = projectId;
    this.gridModalBuilder = new GridModalBuilder();
  }

  buildPillsColumn = (
    field: string,
    headerName: string,
    modalConfig?: (organisationId: number, projectId: number, item: any) => any,
    config?: ISdColDef<any, any>
  ) => {
    let model = new PillsColumnBuilder()
      .makeSelectable()
      .makeEditable()
      .setColumnOptions(PILLS_COLUMN_CONFIG({ field, headerName, ...config }))
      .withCellRenderer(field)
      .setFilterOptions(PILLS_FILTER_CONFIG);

    if (this.canEdit && modalConfig) {
      const pillsColumnModal = (items: any) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(modalConfig(this.organisationId, this.projectId, items))
          .generateModal();
      model.setEditableOnDoubleClick(pillsColumnModal);
      model.withOnDeleteModal(pillsColumnModal);
    } else {
      model.makeEditable(false).makeReadOnly();
    }

    return model;
  };

  buildJSONPillsColumn = (
    field: string,
    headerName: string,
    modalConfig?: (organisationId: number, projectId: number, item: any) => any,
    config?: ISdColDef<any, any>
  ) => {
    let model = new PillsColumnBuilder()
      .makeSelectable()
      .makeEditable()
      .setColumnOptions(
        PILLS_COLUMN_CONFIG({
          field,
          headerName,
          ...config
        })
      )
      .withJSONCellRenderer(field)
      .setFilterOptions(PILLS_FILTER_CONFIG);

    if (this.canEdit && modalConfig) {
      const pillsColumnModal = (items: any) =>
        this.gridModalBuilder
          .constructPopupModal()
          .setModalOptions(modalConfig(this.organisationId, this.projectId, items))
          .generateModal();
      model.setEditableOnDoubleClick(pillsColumnModal);
      model.withOnDeleteModal(pillsColumnModal);
    } else {
      model.makeEditable(false).makeReadOnly();
    }

    return model;
  };

  buildProfilingColumn = (
    field: string,
    fieldId: any,
    headerName: string,
    canEdit: boolean,
    options: any,
    textMatcher: any,
    colOptions?: ISdColDef<any, any>
  ) => {
    let model = new AutocompleteColumnBuilder()
      .makeSelectable(canEdit)
      .makeEditable(canEdit)
      .makeReadOnly(!canEdit)
      .setColumnOptions({
        field: field,
        headerName: headerName,
        headerTooltip: field,
        getQuickFilterText: params => {
          if (!params.data[field]) return "";

          const res = options.find(e => +e.key === params.value);

          if (!res) return "";

          return res.label;
        },
        cellEditorParams: {
          field: field,
          getValueLabel: (ee: any) => {
            const res = options.find(e => e.key === ee + "");
            return res ? res.label : "Unknown";
          },
          options: options ?? []
        },
        ...colOptions
      })
      .withCellEditor()
      .setFilterOptions({
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: textMatcher
        }
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateIdField(fieldId, params.data.id, params.newValue.key);

        return true;
      });

    if (canEdit) {
      model.makeDeletable(true);
    }

    return model;
  };

  buildDynamicColumn = (
    customColumn: any,
    canEdit: boolean,
    saveIntFn?: (customFieldId: number) => (e: number, v: number) => Promise<void>,
    saveStringFn?: (customFieldId: number) => (e: number, v: string) => Promise<void>
  ) => {
    let col = new DynamicColumnBuilder(
      DYNAMIC_COLUMN_CONFIG({
        field: customColumn.colName,
        headerName: customColumn.header
      })
    )
      .makeSelectable(canEdit)
      .makeEditable(canEdit)
      .makeReadOnly(!canEdit)
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (customColumn.customFieldTypeId === CustomColumnFieldTypeEnum.String) {
      if (canEdit && !!saveStringFn) {
        col
          .setValueSetter(params => {
            if (params.newValue === params.oldValue) {
              return;
            }
            saveStringFn(customColumn.customFieldId)(params.data.id, params.newValue);

            return true;
          })
          .makeDeletable(false)
          .disableDelete();
      }
    }

    if (customColumn.customFieldTypeId === CustomColumnFieldTypeEnum.Int) {
      col.setCellDataType("number");
      if (canEdit && !!saveIntFn) {
        col.setValueSetter(params => {
          saveIntFn(customColumn.customFieldId)(params.data.id, params.newValue);

          return true;
        });
      }
    }

    if (customColumn.customFieldTypeId === CustomColumnFieldTypeEnum.RichText) {
      col
        .setColumnOptions({
          cellRenderer: params => {
            return (
              <div className="grid-cell" dangerouslySetInnerHTML={{ __html: params.data[customColumn.colName] }}></div>
            );
          }
        })
        .makeEditable(false);

      if (canEdit && !!saveStringFn) {
        col.setEditableOnDoubleClick(
          saveStringFn(customColumn.customFieldId),
          customColumn.colName,
          customColumn.header
        );
      }
    }

    return col.generateColumnOptions();
  };

  updateTextField = async (field: any, entityId: number, text: string): Promise<boolean> => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.updateField(this.organisationId, this.projectId, entityId, text, 0, null, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
      return true;
    }

    return false;
  };

  updateIdField = async (field: any, entityId: number, id: number) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.updateField(this.organisationId, this.projectId, entityId, "", +id, null, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };

  updateDateField = async (field: any, entityId: number, date: Date) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.updateField(this.organisationId, this.projectId, entityId, "", 0, date, 0, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };

  updateNumericField = async (field: any, entityId: number, numeric: number) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.updateField(this.organisationId, this.projectId, entityId, "", 0, null, numeric, field);
    this.httpProgress.hideTopProgressBarVisible();
    if (res) {
      this.gridToastService.showToast(res.code, res.message);
    }
  };
}
