import { ColDef, ValueFormatterParams } from "ag-grid-community";
import _ from "lodash";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { AudienceStakeholderProfilingField, ProjectStakeholderField, UiSizes } from "../../../../../../enums";
import AudienceStakeholdersApi from "../../../../../../services/api/v2/audenceStakeholders/AudienceStakeholders.api";
import CustomFieldsApi from "../../../../../../services/api/v2/customFields/CustomFields.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { yesNoTextMatcher } from "../../../../filters/TextMatcher";
import {
  AudienceColDefFieldNamesEnum,
  CommonColDefFieldNamesEnum,
  StakeholderColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { RefNumberColumnBuilder } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_builder";
import { REF_NUMBER_COLUMN_CONFIG } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { UiActionColumnBuilder } from "../../columns/commonColumns/UiActionColumn/UiActionColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";

export interface AudienceStakeholdersGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewStakeholders: boolean;
  columns: FP.Entities.IColumnDef[];
  deletePersonFromAudience: any;
}

export class AudienceStakeholdersGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: AudienceStakeholdersGridColumnBuilderProps;
  columnDefs: Dictionary<(header?: string) => ColDef>;
  organisationId: number;
  projectId: number;
  deletePersonFromAudience: any;

  constructor(gridProps: AudienceStakeholdersGridColumnBuilderProps) {
    super(AudienceStakeholdersApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.deletePersonFromAudience = gridProps.deletePersonFromAudience;
    this.projectId = gridProps.projectId;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: header => this.buildNameColumn(header),
      [StakeholderColDefFieldNamesEnum.Email]: (header: string) =>
        new SimpleTextColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.Email,
          headerName: header || I18n.t("grids.email")
        })
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .makeEditable(this.canEdit)
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.Role]: (header: string) =>
        new SimpleTextColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.Role,
          headerName: header || I18n.t("grids.role")
        })
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .makeEditable(this.canEdit)
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.BusinessArea]: (header: string) =>
        new SimpleTextColumnBuilder({
          field: StakeholderColDefFieldNamesEnum.BusinessArea,
          headerName: header || I18n.t("grids.businessArea")
        })
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .makeEditable(this.canEdit)
          .setFilterOptions(BASE_FILTER_CONFIG)
          .generateColumnOptions(),
      [StakeholderColDefFieldNamesEnum.NoteCount]: (header: string) => this.buildNoteCountColumn(header),
      [AudienceColDefFieldNamesEnum.Awareness]: (header: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Awareness,
          header || AudienceColDefFieldNamesEnum.Awareness,
          this.canEdit,
          AudienceStakeholderProfilingField.awareness
        ),
      [AudienceColDefFieldNamesEnum.Understanding]: (header: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Understanding,
          header || AudienceColDefFieldNamesEnum.Understanding,
          this.canEdit,
          AudienceStakeholderProfilingField.understanding
        ),
      [AudienceColDefFieldNamesEnum.Commitment]: (header: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Commitment,
          header || AudienceColDefFieldNamesEnum.Commitment,
          this.canEdit,
          AudienceStakeholderProfilingField.commitment
        ),
      [AudienceColDefFieldNamesEnum.Capability]: (header: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Capability,
          header || AudienceColDefFieldNamesEnum.Capability,
          this.canEdit,
          AudienceStakeholderProfilingField.capability
        ),
      [AudienceColDefFieldNamesEnum.Adoption]: (header: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Adoption,
          header || AudienceColDefFieldNamesEnum.Adoption,
          this.canEdit,
          AudienceStakeholderProfilingField.adoption
        ),
      [StakeholderColDefFieldNamesEnum.Tags]: (header: string) =>
        this.buildPillsColumn(
          StakeholderColDefFieldNamesEnum.Tags,
          header || I18n.t("grids.tags"),
          null //PROJECT_STAKEHOLDER_TAGS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.IsKeyStakeholder]: (header: string) => this.buildIsStakeholderColumn(header),
      [AudienceColDefFieldNamesEnum.UiActionCol]: () =>
        new UiActionColumnBuilder({
          field: AudienceColDefFieldNamesEnum.UiActionCol,
          headerName: "",
          pinned: "right",
          width: 64
        })
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .makeEditable(this.canEdit)
          .setActions([
            {
              id: "add",
              label: I18n.t("phrases.removePerson"),
              onAction: this.deletePersonFromAudience,
              componentProps: {
                className: "mt-2",
                type: ButtonTypes.LINK,
                symbol: IconSymbols.Trash,
                size: UiSizes.XXS,
                isDisabled: !this.canEdit
              },
              rendersIn: UiActionRenderers.BUTTON_ICON
            }
          ])
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    if (this.gridProps.columns.length === 0) {
      return _.map(this.columnDefs, e => {
        return e(null);
      });
    }

    res.push(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());

    this.gridProps.columns.forEach(e => {
      if (e.isCore) {
        let col = this.columnDefs[e.colName];
        if (typeof col === "function") {
          res.push(col(e.header));
        } else {
          throw new Error(`Typeof columnDef with name "${e.colName}" is not a function`);
        }
      } else {
        let col = this.buildDynamicColumn(e, this.gridProps.canEdit);
        res.push(col);
      }
    });

    res.push(this.columnDefs[AudienceColDefFieldNamesEnum.UiActionCol]());

    return res;
  };

  buildAudienceProfilingColumn = (
    field: string,
    headerName: string,
    canEdit: boolean,
    audienceStakeholderProfilingField: AudienceStakeholderProfilingField
  ) => {
    let model = new SimpleTextColumnBuilder({
      field: field,
      headerName: headerName
    })
      .makeEditable(canEdit)
      .makeReadOnly(!canEdit)
      .setCellDataType("number")
      .setColumnOptions({
        valueFormatter: (params: ValueFormatterParams<any>) => {
          if (!params.value || params.value === 0) {
            return "";
          }
          return params.value;
        }
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      model.setColumnOptions({
        valueSetter: params => {
          this.updateProfiling(audienceStakeholderProfilingField, params.data.id, params.newValue);
          return true;
        }
      });
    }
    return model.generateColumnOptions();
  };

  buildNameColumn = (header: string) => {
    let model = new RefNumberColumnBuilder()
      .makeEditable(false)
      .makeSelectable(false)
      .makeReadOnly(true)
      .setColumnOptions({
        ...REF_NUMBER_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.Name,
          headerName: header || I18n.t("grids.name")
        })
      })
      .setFilterOptions(BASE_FILTER_CONFIG);

    return model.generateColumnOptions();
  };

  buildNoteCountColumn = (header: string) => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions({
        ...NOTE_COUNT_COLUMN_CONFIG(),
        field: StakeholderColDefFieldNamesEnum.NoteCount,
        headerName: header || "Note Count"
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    return model.generateColumnOptions();
  };

  buildIsStakeholderColumn = (header: string) => {
    let model = new NameColumnBuilder()
      .makeEditable(false)
      .makeSelectable(false)
      .makeReadOnly(true)
      .setColumnOptions({
        field: AudienceColDefFieldNamesEnum.IsKeyStakeholder,
        headerName: header || I18n.t("grids.keyStakeholder"),
        cellRenderer: YesNoComponent
      })
      .setFilterOptions({
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: yesNoTextMatcher
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable(true);
    }

    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDynamicFieldValueString = customFieldId => async (entityId: number, valueString: string) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateAudienceStakeholderCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      null,
      valueString
    );
  };
  updateDynamicFieldValueInt = customFieldId => async (entityId: number, valueInt: number) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateAudienceStakeholderCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      valueInt,
      null
    );
  };

  updateIsKeyStakeholder = async (entityId: number, text: string) => {
    await this.updateTextField(ProjectStakeholderField.isKeyStakeholder, entityId, text);
  };
  updateSentiment = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.sentiment, entityId, id);
  };
  updateCommitment = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.commitment, entityId, id);
  };
  updateReceptiveness = async (entityId: number, id: number) => {
    await this.updateIdField(ProjectStakeholderField.receptiveness, entityId, id);
  };

  updateProfiling = async (
    audienceStakeholderProfilingField: AudienceStakeholderProfilingField,
    entityId: number,
    value: number
  ) => {
    if ((value <= 0 || value > 5) && value !== null) {
      alert("Value should be more than 0 and less or equal to 5.");
      return;
    }
    await this.updateNumericField(audienceStakeholderProfilingField, entityId, value);
  };
  //#endregion
}

const YesNoComponent = props => {
  let val = props.data[props.colDef.field];
  return val ? I18n.t("phrases.yes") : <span style={{ color: "#aaa" }}>{I18n.t("phrases.no")}</span>;
};
